import { useState, useEffect } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import "../styles/globals.css";
import Head from "next/head";
import Footer from "@/components/navigation/Footer";
import Banner from "@/components/lib/Banner";
import SearchBar, { SearchContext } from "@/components/lib/SearchBar";
import "nprogress/nprogress.css";

import nProgress from "nprogress";
import FilterContextProvider from "stores/FilterContextProvider";
import IniciativaItemProvider from "stores/IniciativasContextProvider";
import GlobalContextProvider from "stores/GlobalContextProvider";

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);
  const [display, setDisplay] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const handleRouteStart = () => nProgress.start();
    const handleRouteDone = () => nProgress.done();

    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteDone);
    router.events.on("routeChangeError", handleRouteDone);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
      router.events.off("routeChangeComplete", handleRouteDone);
      router.events.off("routeChangeError", handleRouteDone);
    };
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8"></meta>
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-LHRFZG60CX"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LHRFZG60CX', {
          page_path: window.location.pathname,
        });
      `,
        }}
      />
      <GlobalContextProvider>
        <FilterContextProvider>
          <IniciativaItemProvider>
            <SearchContext.Provider value={{ display, setDisplay }}>
              <div id="documentRoot" key="app" className="relative h-screen">
                <SearchBar />
                {getLayout(<Component {...pageProps} />)}
                <div className="mt-16 flex flex-row gap-2 sm:mx-4 md:mx-8 md:py-4 lg:mx-28 lg:py-5 xl:mx-auto xl:max-w-[1208px]">
                  <Footer />
                </div>
                <div className="fixed bottom-0 z-50 w-full">
                  <Banner></Banner>
                </div>
              </div>
            </SearchContext.Provider>
          </IniciativaItemProvider>
        </FilterContextProvider>
      </GlobalContextProvider>
    </>
  );
}

export default MyApp;
