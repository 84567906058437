import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const button_sizes = {
  lg: "h-[52px] p-[14px] gap-[12px]",
  md: "h-[44px] p-[12px] gap-[8px]",
  sm: "h-[36px] p-[8px] gap-[4px]",
};

const colors = {
  blue: {
    resting: "bg-primary-blue-900 text-white",
    hover: "hover:bg-primary-blue-800 hover:text-white",
    active: "active:bg-neutral-1000 active:text-primary-blue-700",
    disabled:
      "disabled:bg-primary-blue-900 disabled:opacity-16 disabled:text-white disabled:pointer-events-none",
  },
  green: {
    resting: "bg-primary-green-900 text-neutral-1000",
    hover: "hover:bg-primary-green-800 hover:text-neutral-1000",
    active: "active:bg-neutral-1000 active:text-primary-green-800",
    disabled:
      "disabled:bg-primary-green-900 disabled:opacity-16 disabled:text-neutral-1000 disabled:pointer-events-none",
  },
  red: {
    resting: "bg-red-danger-900 text-neutral-1000",
    hover: "hover:bg-red-danger-700 hover:text-neutral-1000",
    active: "active:bg-neutral-1000 active:text-red-danger-800",
    disabled:
      "disabled:bg-red-danger-900 disabled:opacity-16 disabled:text-neutral-1000 disabled:pointer-events-none",
  },
  yellow: {
    resting: "bg-yellow-warning-800 text-neutral-1000",
    hover: "hover:bg-yellow-warning-700 hover:text-neutral-1000",
    active: "active:bg-neutral-1000 active:text-yellow-warning-900",
    disabled:
      "disabled:bg-yellow-warning-800 disabled:opacity-16 disabled:text-neutral-1000 disabled:pointer-events-none",
  },
  "primary-light": {
    resting: "bg-neutral-50 text-neutral-900 border border-neutral-100",
    hover: "hover:bg-neutral-700 hover:text-white",
    active: "active:bg-neutral-1000 active:text-white",
    disabled:
      "disabled:bg-neutral-900 disabled:opacity-16 disabled:text-white disabled:pointer-events-none",
  },
  "secondary-light": {
    resting: "bg-neutral-900 text-white",
    hover: "hover:bg-neutral-700 hover:text-white",
    active: "active:bg-neutral-1000 active:text-white",
    disabled:
      "disabled:bg-neutral-900 disabled:opacity-16 disabled:text-white disabled:pointer-events-none",
  },
  "terciary-light": {
    resting: "border border-neutral-1000 text-neutral-900",
    hover:
      "hover:border border-neutral-900 hover:text-white hover:opacity-100 hover:bg-neutral-800",
    active: "active:bg-neutral-1000 active:text-white",
    disabled:
      "disabled:bg-transparent disabled:opacity-16 disabled:text-neutral-800 disabled:pointer-events-none",
  },
  "ghost-light": {
    resting: "text-neutral-900",
    hover: "hover:bg-neutral-50 text-neutral-1000",
    active: "active:bg-neutral-100 active:text-neutral-1000",
    disabled:
      "disabled:text-neutral-900 disabled:opacity-16 disabled:pointer-events-none",
  },
  "primary-dark": {
    resting: "border border-neutral-800 bg-neutral-800 text-neutral-100",
    active: "hover:bg-neutral-50 hover:text-neutral-1000",
    hover: "active:bg-white active:text-neutral-1000",
    disabled:
      "disabled:text-neutral-100 disabled:opacity-16 disabled:border disabled:border-white disabled:pointer-events-none",
  },
  "secondary-dark": {
    resting: "bg-neutral-100 text-neutral-1000",
    hover: "hover:bg-neutral-50 hover:text-neutral-1000",
    active: "active:bg-white active:text-neutral-1000",
    disabled:
      "disabled:text-neutral-100 disabled:opacity-16 disabled:pointer-events-none",
  },
  "terciary-dark": {
    resting: "border border-neutral-800 bg-neutral-800 text-neutral-100",
    hover: "hover:bg-neutral-50 hover:text-neutral-1000",
    active: "active:bg-white active:text-neutral-1000",
    disabled:
      "disabled:text-neutral-100 disabled:opacity-16 disabled:border disabled:border-white disabled:pointer-events-none",
  },
  "ghost-dark": {
    resting: "text-neutral-100",
    hover: "hover:bg-neutral-800 hover:text-white",
    active: "active:bg-white active:text-neutral-1000",
    disabled:
      "disabled:text-neutral-100 disabled:opacity-16  disabled:pointer-events-none",
  },
  translucent: {
    resting: "bg-neutral-1000/[.24] text-white",
    hover: "hover:bg-neutral-1000/[.48]   hover:text-white",
    active: "active:bg-white active:text-neutral-1000",
    disabled:
      "disabled:text-white bg-neutral-1000/[.24] disabled:opacity-4 disabled:opacity-16  disabled:pointer-events-none",
  },
  "white-on-black": {
    resting: "bg-neutral-900 text-white",
    hover: "hover:bg-neutral-700 hover:text-white",
    active: "active:bg-neutral-1000 active:text-white",
    disabled:
      "disabled:text-white disabled:opacity-16 disabled:pointer-events-none",
  },
  "white-on-black-no-hover": {
    resting: "bg-neutral-900 text-white",
  },
};

const modifyElement = (child) => {
  const className = classNames(child.props.className, "h-5 w-5");

  const props = {
    className,
  };

  return React.cloneElement(child, props);
};

const getThemeClasses = (color) => {
  const theme = colors[color];
  return classNames(theme.resting, theme.hover, theme.active, theme.disabled, "focus:outline-none focus:shadow-none");
};

export default function Button({
  size = "md",
  className = "",
  leadingIcon,
  trailingIcon,
  color = "blue",
  label = "",
  skeleton = false,
  ...rest
}) {
  const base_style = "flex flex-row justify-center items-center text-[14px]";
  const skeleton_style = "bg-neutral-50 ";
  const themeClasses = getThemeClasses(color);

  const css = classNames(
    button_sizes[size],
    base_style,
    themeClasses,
    className
  );

  return (
    <>
      {!skeleton || !label ? (
        <button {...rest} className={css}>
          <>
            {leadingIcon ? modifyElement(leadingIcon) : null}
            <span className="opacity-100 filter-none">{label}</span>
            {trailingIcon ? modifyElement(trailingIcon) : null}
          </>
        </button>
      ) : (
        <button
          className={classNames(button_sizes[size], skeleton_style)}
        ></button>
      )}
    </>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  color: PropTypes.oneOf([
    "blue",
    "green",
    "red",
    "yellow",
    "secondary-light",
    "terciary-light",
    "ghost-light",
    "secondary-dark",
    "terciary-dark",
    "ghost-dark",
    "translucent",
    "white-on-black",
    "white-on-black-no-hover",
    "primary-light",
  ]),
  text: PropTypes.string,
  leadingIcon: PropTypes.object,
  trailingIcon: PropTypes.object,
  skeleton: PropTypes.bool,
};
