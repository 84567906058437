import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Text from "@/components/text";

const sizeObject = {
  xl: "border-b-[1px]",
  lg: "border-b-[1px]",
  md: "border-b-[1px]",
  sm: "border-b-[1px]",
};

const themeObject = {
  light:
    "hover:bg-neutral-1000/[0.08] hover:border-neutral-1000 border-b border-neutral-1000 active:bg-neutral-1000  active:text-white",
  dark: " border-b border-white text-white hover:bg-white/[0.24] active:text-black active:bg-white",
};

const iconSize = {
  xl: "h-4 w-4",
  lg: "h-3.5 w-3.5",
  md: "h-3.5 w-3.5",
  sm: "h-3 w-3",
};

export function Link({
  label = "Placeholder",
  size = "md",
  theme = "light",
  className = "",
  leadingIcon,
  trailingIcon,
  as = "span",
  ...rest
}) {
  const Tag = as;
  const css = classNames(
    className,
    sizeObject[size],
    themeObject[theme],
    "flex items-center cursor-pointer w-fit  space-x-1  "
  );

  const modifyElement = (child) => {
    const className = classNames(child.props.className, iconSize[size]);

    const props = {
      className,
    };

    return React.cloneElement(child, props);
  };

  return (
    <Tag {...rest} className={css}>
      {leadingIcon ? modifyElement(leadingIcon) : null}
      <Text.Label className={className} as="span" size={size}>
        {label}
      </Text.Label>
      {trailingIcon ? modifyElement(trailingIcon) : null}
    </Tag>
  );
}

Link.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  leadingIcon: PropTypes.object,
  trailingIcon: PropTypes.object,
};
