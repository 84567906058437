import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[-0.008rem] text-[4.5rem] leading-[4.625rem] ",
  lg: "tracking-[-0.008rem] text-[3.75rem] leading-[3.875rem]",
  md: "tracking-[-0.008rem] text-[3rem] leading-[3.125rem]  ",
  sm: "tracking-[-0.008rem] text-[2.5rem] leading-[2.625rem] ",
  xs: "tracking-[-0.008rem] text-[1.5rem] leading-[2.125rem] ",
};

const weightObject = {
  extrabold: "font-extrabold",
  light: "font-light",
};

export function Display({ children, as, size, fontWeight, className }) {
  const Tag = as;
  const css = classNames(
    sizesObject[size],
    weightObject[fontWeight],
    className
  );
  return <Tag className={css}>{children}</Tag>;
}

Display.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]),
  fontWeight: PropTypes.oneOf(["extrabold", "light"]),
};

Display.defaultProps = {
  as: "span",
  size: "md",
  fontWeight: "extrabold",
  className: "",
};
