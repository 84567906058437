import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[-0.008rem] text-[1.125rem] leading-[1.75rem]",
  lg: "tracking-[-0.008rem] text-[1rem] leading-[1.5rem]",
  md: "tracking-[-0.008rem] text-[0.875rem] leading-[1.25rem]",
  sm: "tracking-[-0.008rem] text-[0.75rem] leading-[1rem]",
};

export function Body({ children, as, size, className, ...rest }) {
  const Tag = as;
  const css = classNames(sizesObject[size], className);
  return (
    <Tag {...rest} className={css}>
      {children}
    </Tag>
  );
}

Body.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
};

Body.defaultProps = {
  as: "span",
  size: "md",
  className: "",
};
