import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[-0.008rem] text-[1.375rem] leading-[1.625rem]",
  lg: "tracking-[-0.008rem] text-[1.125rem] leading-[1.5rem]",
  md: "tracking-[-0.008rem] text-[1rem] leading-[1.375rem]",
  sm: "tracking-[-0.008rem] text-[0.875rem] leading-[1.125rem]",
};

const fontWeightsObject = {
  bold: "font-bold",
  normal: "font-normal",
  light: "font-light",
};

export function Title({
  children,
  as = "span",
  size = "md",
  fontWeight = "bold",
  className,
}) {
  const Tag = as;
  const css = classNames(
    sizesObject[size],
    className,
    fontWeightsObject[fontWeight]
  );
  return <Tag className={css}>{children}</Tag>;
}

Title.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  fontWeight: PropTypes.oneOf(["bold", "normal", "light"]),
};

Title.defaultProps = {
  as: "span",
  size: "md",
  className: "",
  fontWeight: "bold",
};
