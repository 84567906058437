import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const fontWeights = {
  bold: "font-bold",
  light: "font-light",
};

const sizesObject = {
  xl: "tracking-[-0.008rem] text-[2.25rem] leading-[2.5rem]",
  lg: "tracking-[-0.008rem] text-[2rem] leading-[2.25rem]",
  md: "tracking-[-0.008rem] text-[1.75rem] leading-[2rem]",
  sm: "tracking-[-0.008rem] text-[1.5rem] leading-[1.75rem]",
};

export function Headline({ children, as, size, className, fontWeight }) {
  const Tag = as;
  const css = classNames(className, sizesObject[size], fontWeights[fontWeight]);
  return <Tag className={css}>{children}</Tag>;
}

Headline.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  fontWeight: PropTypes.oneOf(["bold", "light"]),
};

Headline.defaultProps = {
  as: "span",
  size: "md",
  fontWeight: "bold",
  className: "",
};
