import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[0.08rem] text-[1rem] leading-[1.5rem] uppercase",
  lg: "tracking-[0.08rem] text-[0.875rem] leading-[1.25rem] uppercase",
  md: "tracking-[0.08rem] text-[0.75rem] leading-[1rem] uppercase",
  sm: "tracking-[0.08rem] text-[0.625rem] leading-[0.75rem] uppercase",
};

export function Overline({ children, as, size, className }) {
  const Tag = as;
  const css = classNames(sizesObject[size], className);
  return <Tag className={css}>{children}</Tag>;
}

Overline.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
};

Overline.defaultProps = {
  as: "span",
  size: "md",
  className: "",
};
