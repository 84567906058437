import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  "01": "tracking-normal font-semibold text-[0.813rem] leading-[1rem] sm:text-[0.813rem] sm:leading-[1rem] md:text-[0.813rem] md:leading-[1rem]",
};

export function NavigationLabel({ children, as, size, className }) {
  const Tag = as;
  const css = classNames(sizesObject[size], className);
  return <Tag className={css}>{children}</Tag>;
}

NavigationLabel.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["01"]),
};

NavigationLabel.defaultProps = {
  as: "span",
  size: "01",
  className: "",
};
