import React, { createContext, useReducer } from "react";

export const IniciativaItemContext = createContext();
export const IniciativaItemDispatchContext = createContext();

export const initiativesMap = {
  todas: "Todas las películas",
  "gira-ambulante": "Gira de Documentales",
  "ambulante-mas-alla": "Ambulante Más Allá",
  "ambulante-presenta": "Ambulante Presenta",
};

function iniciativaItemReducer(state, action) {
  switch (action.type) {
    case "setSelectedItem":
      return { ...state, selectedItem: action.payload };
    case "setKeyword":
      return { ...state, keyword: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

export function setSelectedItem(dispatch, item) {
  dispatch({ type: "setSelectedItem", payload: item });
}

export function setKeyword(dispatch, keyword) {
  dispatch({ type: "setKeyword", payload: keyword });
}

export default function IniciativaItemProvider({ children }) {
  const initialState = {
    selectedItem: "todas",
    keyword: "",
  };
  const [state, dispatch] = useReducer(iniciativaItemReducer, initialState);

  return (
    <IniciativaItemContext.Provider value={state}>
      <IniciativaItemDispatchContext.Provider value={dispatch}>
        {children}
      </IniciativaItemDispatchContext.Provider>
    </IniciativaItemContext.Provider>
  );
}
