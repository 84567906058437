import axios from "axios";
import qs from "qs";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { createContext } from "react";
import apiClient from "utils/apiClient";

export const FilterContext = createContext(null);
export const FilterDispatchContext = createContext(null);

export function handleChangeFilter(dispatch, filter) {
  dispatch({
    type: "changed",
    filter: filter,
  });
}

export function clearAll(dispatch, filters) {
  const res = filters.map((filter) => {
    return { ...filter, isAdded: false };
  });
  dispatch({
    type: "set",
    filters: res,
  });
}

export function setInitialFilters(dispatch, filters) {
  dispatch({
    type: "set",
    filters: filters,
  });
}

const getYears = (years) => {
  const listYears = Object.values(years).map((y) => {
    return {
      key: y.year.toString(),
      name: y.year,
      id: y.id,
      isAdded: false,
      category: "Years",
      uid: y.year.toString().concat("Years"),
    };
  });
  return listYears;
};

const formatList = (list, category) => {
  const res = list.map(({ id, attributes }) => {
    return {
      key: attributes.name,
      id,
      name: attributes.name,
      isAdded: false,
      category,
      uid: attributes.name.concat(category),
    };
  });
  return res;
};

const formatAmagenerations = (list, category) => {
  const res = list.map(({ id, attributes }) => {
    return {
      key: attributes.ama_title,
      id,
      name: attributes.ama_title,
      isAdded: false,
      category,
      uid: attributes.ama_title.concat(category),
    };
  });
  return res;
};

function filterReducer(filters, action) {
  switch (action.type) {
    case "changed": {
      return filters.map((f) => {
        if (f.uid === action.filter.uid) {
          return action.filter;
        } else {
          return f;
        }
      });
    }
    case "set": {
      return action.filters;
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export default function FilterContextProvider({ children }) {
  const formatCountries = (countries) => {
    let list = Object.values(countries).map((c) => {
      return {
        name: c.countries,
        id: c.countries,
        isAdded: false,
        category: "Pais",
        uid: c.countries.concat("Pais"),
      };
    });
    return list;
  };

  const [filters, dispatch] = useReducer(filterReducer, []);

  const generalQueryDesc = qs.stringify({
    sort: ["name:desc"],
  });

  const generalQueryAsc = qs.stringify({
    sort: ["name:asc"],
    pagination: {
      pageSize: 100,
    },
  });

  const generalQueryDescId = qs.stringify({
    sort: ["id:asc"],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          { data: sections },
          { data: presentaeditions },
          { data: giraeditions },
          { data: amagenerations },
        ] = await axios.all([
          apiClient.get(`/api/sections?${generalQueryAsc}`),
          apiClient.get(
            `/api/ediciones-ambulante-presentas?${generalQueryDesc}`
          ),
          apiClient.get(`/api/ediciones-gira?${generalQueryDesc}`),
          apiClient.get(`/api/ama-generaciones?${generalQueryDescId}`),
        ]);
        const countries = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/components-selects-countries`
        );
        const years = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/components-selects-years`
        );

        setInitialFilters(dispatch, [
          ...getYears(years.data),
          ...formatCountries(countries.data),
          ...formatList(sections.data, "Secciones"),
          ...formatList(presentaeditions.data, "EAP"),
          ...formatList(giraeditions.data, "EGIRA"),
          ...formatAmagenerations(amagenerations.data, "Generaciones"),
        ]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <FilterContext.Provider value={filters}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
}
