import React, { createContext } from "react";
import apiClient from "@/utils/apiClient";
import useSWR from "swr";

export const GlobalContext = createContext(null);

export default function GlobalContextProvider({ children }) {
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR("/api/configuracion-global", fetcher);
  const globalConfig = data?.data?.attributes;

  return (
    <GlobalContext.Provider value={globalConfig}>
      {children}
    </GlobalContext.Provider>
  );
}
