import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[0.02rem] text-[22px] leading-[1.75rem]",
  lg: "tracking-[0.02rem] text-[18px] leading-[1.5rem]",
  md: "tracking-[0.02rem] text-[16px] leading-[1.25rem]",
  sm: "tracking-[0.02rem] text-[14px] leading-[1rem]",
};

const weightObject = {
  light: "font-light",
  normal: "font-normal",
  bold: "font-bold",
};

export function Noun({
  children,
  as = "span",
  size = "md",
  fontWeight = "normal",
  className,
}) {
  const Tag = as;
  const css = classNames(
    sizesObject[size],
    weightObject[fontWeight],
    className
  );
  return <Tag className={css}>{children}</Tag>;
}

Noun.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  fontWeight: PropTypes.oneOf(["light", "normal", "bold"]),
};
