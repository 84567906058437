import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  "01": "tracking-normal text-[1rem] leading-[1.5rem] sm:text-[1.125rem] sm:leading-[1.625rem] md:text-[1.125rem] md:leading-[1.625rem]",
  "02": "tracking-normal text-[0.938rem] leading-[1.5rem] sm:text-[1rem] sm:leading-[1.5rem] md:text-[1rem] md:leading-[1.5rem]",
};

export function Paragraph({ children, as, size, className }) {
  const Tag = as;
  const css = classNames(sizesObject[size], className);
  return <Tag className={css}>{children}</Tag>;
}

Paragraph.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["01", "02"]),
};

Paragraph.defaultProps = {
  as: "span",
  size: "01",
  className: "",
};
