import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  xl: "tracking-[0.02rem] text-[1.125rem] leading-[1.75rem]",
  lg: "tracking-[0.02rem] text-[1rem] leading-[1.5rem]",
  md: "tracking-[0.02rem] text-[0.875rem] leading-[1.25rem]",
  sm: "tracking-[0.02rem] text-[0.75rem] leading-[1rem]",
};

const weightObject = {
  bold: "font-bold",
  normal: "font-normal",
};

export function Label({ children, as, size, fontWeight, className }) {
  const Tag = as;
  const css = classNames(
    sizesObject[size],
    weightObject[fontWeight],
    className
  );
  return <Tag className={css}>{children}</Tag>;
}

Label.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  fontWeight: PropTypes.oneOf(["bold", "normal"]),
};

Label.defaultProps = {
  as: "span",
  size: "md",
  fontWeight: "normal",
  className: "",
};
