import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const sizesObject = {
  "01": "tracking-normal font-semibold text-[0.75rem] leading-[1rem] sm:text-[0.875rem] sm:leading-[1.25rem] md:text-[0.875rem] md:leading-[1.25rem]",
  "02": "tracking-normal font-semibold text-[0.625rem] leading-[0.875rem] sm:text-[0.625rem] sm:leading-[0.875rem] md:text-[0.625rem] md:leading-[0.875rem]",
};

export function ButtonLabel({ children, as, size, className }) {
  const Tag = as;
  const css = classNames(sizesObject[size], className);
  return <Tag className={css}>{children}</Tag>;
}

ButtonLabel.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["01", "02"]),
};

ButtonLabel.defaultProps = {
  as: "span",
  size: "01",
  className: "",
};
