/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import { MegaphoneIcon, XIcon } from "@heroicons/react/outline";
import * as Text from "@/components/text";
import Button from "@/components/actions/Button";
import { motion } from "framer-motion";
import useLocalStorage from "@/hooks/useLocalStorage";

const variants = {
  open: { opacity: 1, display: "block" },
  closed: { opacity: 0, display: "none" },
};

export default function Banner({ children, ...rest }) {
  const [accept_cookies, toggleAcceptCookies] = useLocalStorage(
    "ambulante_accept_cookies",
    false
  );
  return (
    <>
      <motion.div
        initial={{ opacity: 0, display: "none" }}
        animate={!accept_cookies ? "open" : "closed"}
        transition={{ duration: 0.5 }}
        variants={variants}
        exit={{ opacity: 0 }}
        className="relative inset-x-0 bottom-0 "
      >
        <div className="relative bg-white shadow-lg">
          <div className="mx-auto max-w-screen-xl px-3 py-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between space-y-3">
              <Text.Title size="lg" className="text-2xl font-bold">
                Manejo de Cookies
              </Text.Title>
              <Text.Body size="lg">
                Ambulante utiliza cookies estrictamente necesarias, así como
                otras tecnologías similares, para poner en funcionamiento este
                sitio web y brindarle una mejor experiencia de usuario. Sin
                embargo, además de las cookies estrictamente necesarias,
                Ambulante se apoya en las cookies opcionales para diferentes
                fines, como ofrecerle una navegación personalizada; realizar
                análisis mediante el conteo de visitas y fuentes de tráfico;
                mostrar anuncios; e interactuar con terceros.
              </Text.Body>
              <Button
                onClick={() => toggleAcceptCookies(true)}
                color="secondary-light"
                label="Aceptar"
              />
              <XIcon
                onClick={() => toggleAcceptCookies(true)}
                className="absolute right-5  top-0 h-6 w-6 text-neutral-800"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
